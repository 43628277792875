import { UserListFilters } from '../../services/user.service';
import { CreateLessonPayload, TrainingLocalesEnum } from '../../../shared/models/educations-model';

export class GetLessonsCategoriesListForFilter {
  static readonly type = '[Educations] Get lessons categories list';
}

export class GetUserListForFilter {
  static readonly type = '[Educations] Get user list for filter';

  constructor(public params: UserListFilters) {}
}

export class GetLessons {
  static readonly type = '[Educations] Get lessons list';
}

export class ClearLessons {
  static readonly type = '[Educations] ClearLessons';
}

export class GetLessonById {
  static readonly type = '[Educations] Get lesson by id';

  constructor(public id: string | number) {}
}

export class CreateLesson {
  static readonly type = '[Training] Create lesson';

  constructor(public payload: CreateLessonPayload) {}
}

export class CheckUserCompletePlan {
  static readonly type = '[Educations] CheckUserCompletePlan';
}

export class UpdateLesson {
  static readonly type = '[Educations] Update lesson';

  constructor(
    public id: string | number,
    public payload: CreateLessonPayload,
  ) {}
}

export class DeleteLessonById {
  static readonly type = '[Educations] Delete lesson by id';

  constructor(public id: string | number) {}
}

export class ConfirmSeenById {
  static readonly type = '[Educations] Confirm seen lesson';

  constructor(public id: string) {}
}

export class CloseConfirmPopup {
  static readonly type = '[Educations] Close confirm popup';

  constructor(public isCloseDoc: boolean) {}
}

export class IsEducationDataLoaded {
  static readonly type = '[Educations] Is education data loaded';

  constructor(public isEducationDataLoaded: boolean) {}
}

export class SaveLessonPosition {
  static readonly type = '[Educations] SaveLessonPosition';

  constructor(
    public id: string,
    public position: string,
  ) {}
}

export class UpdateUserLocaleSetting {
  static readonly type = '[Educations] UpdateUserLocaleSetting';

  constructor(public locale: TrainingLocalesEnum) {}
}

export class GetFullLessonById {
  static readonly type = '[Educations] GetFullLessonById';

  constructor(public id: string) {}
}

export class ClearLessonData {
  static readonly type = '[Educations] ClearLessonData';
}

export class DeleteLessonFilesByLessonId {
  static readonly type = '[Educations] DeleteLessonFilesByLessonId';

  constructor(
    public lessonId: string,
    public files: { [key: string]: Array<'lesson' | 'preview'> },
  ) {}
}

export class GetSkillCategoriesList {
  static readonly type = '[Plan] GetSkillCategoriesList';
}
