import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { take, tap } from 'rxjs/operators';
import {
  CheckUserCompletePlan,
  ClearLessonData,
  ClearLessons,
  CloseConfirmPopup,
  ConfirmSeenById,
  CreateLesson,
  DeleteLessonById,
  DeleteLessonFilesByLessonId,
  GetFullLessonById,
  GetLessonById,
  GetLessons,
  GetLessonsCategoriesListForFilter,
  GetSkillCategoriesList,
  GetUserListForFilter,
  IsEducationDataLoaded,
  SaveLessonPosition,
  UpdateLesson,
  UpdateUserLocaleSetting,
} from './educations.actions';
import { EducationsService } from '../../services/education.service';
import { Injectable } from '@angular/core';
import { LessonsFilters } from '../filters/filters.models';
import { FiltersKeyEnum } from '../../../shared/enums/filtersKey.enum';
import { Observable } from 'rxjs';
import {
  CheckUserCompletePlanModel,
  EducationListCategory,
  EducationListLessonModel,
  LessonModel,
  UserListForFilterModel,
} from '../../../shared/models/educations-model';
import { IdTitleObject } from '../../../shared/models';

export interface LessonsStateModel {
  lessonData: EducationListLessonModel;
  nameLesson: string;
  description: string;
  category: string;
  listCategory: EducationListCategory[];
  userListForFilter: UserListForFilterModel[];
  listLessons: EducationListLessonModel[];
  lessonId: string;
  lessonById: EducationListLessonModel | object;
  url: string;
  totalLessons: number;
  page: number;
  isCloseDoc: boolean;
  isEducationDataLoaded: boolean;
  defaultCategory: EducationListCategory[];
  isLoading: boolean;
  isComplete: {
    next: string;
    complete: boolean;
  };
  skillCategoriesList: IdTitleObject[];
}

export const defaultState: LessonsStateModel = {
  lessonData: null,
  nameLesson: '',
  description: '',
  category: '',
  url: '',
  listCategory: [],
  listLessons: [],
  lessonId: '',
  lessonById: {},
  userListForFilter: [],
  totalLessons: 0,
  page: 1,
  isCloseDoc: false,
  isEducationDataLoaded: false,
  defaultCategory: [],
  isLoading: true,
  isComplete: {
    next: null,
    complete: false,
  },
  skillCategoriesList: null,
};

@State({
  name: 'lessons',
  defaults: defaultState,
})
@Injectable()
export class EducationState {
  constructor(
    private service: EducationsService,
    private store: Store,
  ) {}

  @Selector()
  static skillCategoriesList(state): EducationListLessonModel {
    return state.skillCategoriesList;
  }

  @Selector()
  static lessonData(state): EducationListLessonModel {
    return state.lessonData;
  }

  @Selector()
  static isComplete(state): { next: string; complete: boolean } {
    return state.isComplete;
  }

  @Selector()
  static isLoading(state): boolean {
    return state.isLoading;
  }

  @Selector()
  static nameLesson(state): string {
    return state.nameLesson;
  }

  @Selector()
  static description(state): string {
    return state.description;
  }

  @Selector()
  static userListForFilter(state): UserListForFilterModel[] {
    return state.userListForFilter;
  }

  @Selector()
  static totalLessons(state): number {
    return state.totalLessons;
  }

  @Selector()
  static category(state): string {
    return state.category;
  }

  @Selector()
  static url(state): string {
    return state.url;
  }

  @Selector()
  static listCategory(state): EducationListCategory[] {
    return state.listCategory;
  }

  @Selector()
  static defaultCategory(state): EducationListCategory[] {
    return state.defaultCategory;
  }

  @Selector()
  static listLessons(state): EducationListLessonModel[] {
    return state.listLessons;
  }

  @Selector()
  static lessonId(state): EducationListLessonModel {
    return state.lessonId;
  }

  @Selector()
  static lessonById(state): EducationListLessonModel {
    return state.lessonById;
  }

  @Selector()
  static page(state): number {
    return state.page;
  }

  @Selector()
  static isCloseDoc(state): boolean {
    return state.isCloseDoc;
  }

  @Selector()
  static isEducationDataLoaded(state): boolean {
    return state.isEducationDataLoaded;
  }

  @Action(GetLessonsCategoriesListForFilter)
  getLessonsCategoriesListForFilter(ctx: StateContext<LessonsStateModel>): Observable<EducationListCategory[]> {
    return this.service.getLessonCategoriesForFilter().pipe(
      tap((data: EducationListCategory[]) => {
        ctx.patchState({
          listCategory: data,
          defaultCategory: data.filter((category: EducationListCategory) => category?.isDefault),
        });
      }),
    );
  }

  @Action(GetUserListForFilter)
  getUserListForFilter(ctx: StateContext<LessonsStateModel>, { params }): Observable<UserListForFilterModel[]> {
    return this.service.getUserListForFilter(params).pipe(
      tap((users: UserListForFilterModel[]) => {
        ctx.patchState({
          userListForFilter: users.map((user: UserListForFilterModel) => {
            return {
              name: user.name?.trim() || '',
              email: user.email,
            };
          }),
        });
      }),
    );
  }

  @Action(GetLessons)
  getAllLessons(ctx: StateContext<LessonsStateModel>) {
    ctx.patchState({
      isLoading: true,
    });
    const filtersValue: LessonsFilters = this.store.selectSnapshot(({ filters }) => filters[FiltersKeyEnum.Lessons].model);
    return this.service.getLessons(filtersValue).pipe(
      tap((res: { listLessons: EducationListLessonModel[]; totalLessons: number }) => {
        ctx.patchState({
          listLessons: res.listLessons,
          totalLessons: res.totalLessons,
          isLoading: false,
        });
      }),
    );
  }

  @Action(ClearLessons)
  clearLessons(ctx: StateContext<LessonsStateModel>) {
    ctx.patchState({
      listLessons: [],
      totalLessons: 0,
      isComplete: {
        next: null,
        complete: false,
      },
    });
  }

  @Action(GetLessonById)
  getLessonById(ctx: StateContext<LessonsStateModel>, { id }: GetLessonById): Observable<EducationListLessonModel> {
    return this.service.getLessonsById(id).pipe(
      tap((data: EducationListLessonModel) => {
        ctx.patchState({
          lessonById: data,
        });
      }),
    );
  }

  @Action(CreateLesson)
  createLesson(ctx: StateContext<LessonsStateModel>, { payload }: CreateLesson): Observable<LessonModel> {
    return this.service.createLessons(payload).pipe(
      tap((res: LessonModel) => {
        ctx.patchState({
          lessonId: res.id,
        });
      }),
    );
  }

  @Action(CheckUserCompletePlan)
  checkUserCompletePlan(ctx: StateContext<LessonsStateModel>): Observable<CheckUserCompletePlanModel> {
    return this.service.checkUserCompletePlan().pipe(
      tap((res: CheckUserCompletePlanModel) => {
        ctx.patchState({
          isComplete: {
            next: res.isComplete.next || null,
            complete: res.isComplete.complete,
          },
        });
      }),
    );
  }

  @Action(UpdateLesson)
  updateLesson(ctx: StateContext<LessonsStateModel>, { id, payload }: UpdateLesson) {
    return this.service.updateLesson(id, payload);
  }

  @Action(DeleteLessonById)
  deleteLessonById(ctx: StateContext<LessonsStateModel>, { id }: DeleteLessonById): Observable<void> {
    return this.service.deleteLesson(id);
  }

  @Action(ConfirmSeenById)
  confirmSeenById(ctx: StateContext<LessonsStateModel>, { id }: ConfirmSeenById): Observable<EducationListLessonModel> {
    ctx.patchState({
      isLoading: true,
    });
    return this.service.confirmSeen(id).pipe(
      tap(() => {
        const updatedListLesson: EducationListLessonModel[] = ctx.getState().listLessons.map((lesson: EducationListLessonModel) => {
          if (lesson.id === id) {
            lesson.isSeen = true;
          }
          return lesson;
        });
        ctx.patchState({
          listLessons: updatedListLesson,
          isLoading: false,
        });
      }),
    );
  }

  @Action(CloseConfirmPopup)
  closeConfirmPopup(ctx: StateContext<LessonsStateModel>, { isCloseDoc }: CloseConfirmPopup): void {
    ctx.patchState({
      isCloseDoc,
    });
  }

  @Action(IsEducationDataLoaded)
  isEducationDataLoaded(ctx: StateContext<LessonsStateModel>, { isEducationDataLoaded }: IsEducationDataLoaded): void {
    ctx.patchState({
      isEducationDataLoaded,
    });
  }

  @Action(SaveLessonPosition)
  saveLessonPosition(ctx: StateContext<LessonsStateModel>, { id, position }): void {
    this.service.saveLessonPosition(id, position).pipe(take(1)).subscribe();
  }

  @Action(UpdateUserLocaleSetting)
  updateUserLocaleSetting(ctx: StateContext<LessonsStateModel>, { locale }: UpdateUserLocaleSetting): void {
    this.service
      .updateUserLocaleSetting(locale)
      .pipe(take(1))
      .subscribe(() => {
        ctx.dispatch(new GetLessons());
      });
  }

  @Action(GetFullLessonById)
  getFullLessonById(ctx: StateContext<LessonsStateModel>, { id }: GetFullLessonById): void {
    this.service
      .getFullLessonById(id)
      .pipe(take(1))
      .subscribe((res: EducationListLessonModel) => {
        ctx.patchState({
          lessonData: res,
        });
      });
  }

  @Action(ClearLessonData)
  clearLessonData(ctx: StateContext<LessonsStateModel>) {
    ctx.patchState({
      lessonData: null,
    });
  }

  @Action(DeleteLessonFilesByLessonId)
  deleteLessonFilesByLessonId(ctx: StateContext<LessonsStateModel>, { lessonId, files }: DeleteLessonFilesByLessonId) {
    return this.service.deleteLessonFilesByLessonId(lessonId, files);
  }

  @Action(GetSkillCategoriesList)
  getSkillCategoriesList(ctx: StateContext<LessonsStateModel>) {
    this.service
      .getSkillCategoriesList()
      .pipe(take(1))
      .subscribe((res: IdTitleObject[]) => {
        ctx.patchState({
          skillCategoriesList: res,
        });
      });
  }
}
